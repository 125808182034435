import coursesGrid1 from "../extra-images/country/canada-1.jpg";
import coursesGrid2 from "../extra-images/country/uk.jpg";
import coursesGrid3 from "../extra-images/country/france.jpg";
import coursesGrid4 from "../extra-images/country/germany.jpg";
import coursesGrid5 from "../extra-images/country/dubai.jpg";

let countryDetails = [];
countryDetails[1] = {
  name: "Why Study in Canada: The Perks",
  image: coursesGrid1,
  link: "/study-in-canada",
  pageTitle: "Why Study in Canada: The Perks",
  pageDesc:
    "<h6>At a Glance</h6><p>Are you considering pursuing your further education in Canada? Are you curious to find out more about your overseas study options? Guidance Plus is available to assist. We have over ten years of experience and are experts at assisting students just like you in navigating the challenges of studying in Canada. Our counsellors are prepared to offer you whatever information you require, including information on course alternatives, college options, cost schedules, and application procedures. Make your dream of attending university in Canada a reality by getting in touch with us for individualized advice!</p><h6>The perks</h6><p>The world’s second-largest country Canada is one of the favorite destinations for study abroad aspirants. It is one of the safest countries in the world. The country offers several cutting-edge facilities to the students who come to study in Canada. Moreover, it’s one of the most industrialized countries in the world, opening up greater opportunities for students. The country possesses a diverse and all-inclusive environment welcoming students from around the globe. The country ranks 3rd for the best quality of life. The country tries to offer the same sort of rights and facilities to international students just like that are available to a native.</p>",
  pageContent: [
    {
      heading: "Some additional benefits",
      content: `<ol>
        <li>Qualifications are valued around the world.</li>
        <li>Affordable education.</li>
        <li>Multicultural society.</li>
        <li>Healthy and safe communities.</li>
        <li>Possibility of paid internships while studying.</li>
        <li>Post-study work visa</li>
        <li>Good job opportunities.</li>
        <li>Fast-track PR options</li>
        <li>Scholarship availability</li>
        <li>Part-time opportunities</li>
        </ol>`,
    },
    {
      heading: "The Procedures",
      content: `<p>International students can apply to Canada in three main intakes: September, January, and May. Apart from the aforementioned significant intakes, there are a handful of smaller ones, with restricted openings between July and October. This implies that you have a variety of possibilities to fulfil your desire of attending a Canadian university for higher education.</p>
      <ol>
          <li>Choose a course and an institution.</li>
          <li>Application process</li>
          <li>Documentation and fee submission</li>
          <li>Medical examination</li>
          <li>Visa proceedings</li>
          <li>Find accommodation</li>
          </ol>`,
    },
    {
      heading: "Universities recommended by us.",
      content: "",
    },
    {
      heading: "Am I eligible for scholarships?",
      content:
        "<p>If you're struggling with budgetary issues in your arrange to study in Canada, there's no need to stress around those. Numerous grants are accessible for understudies arranging to ponder in Canada. There are grants and budgetary help given by distinctive teach and organizations. All you have got to do is interface with a perfect think about overseas organization to help you in your methods.</p>",
    },
    {
      heading: "Prominent scholarships",
      content: `
      <ol>
        <li>Canadian Commonwealth Scholarship and Fellowship Plan</li>
        <li>Ontario Graduate Scholarship Program</li>
        <li>Dick Martin Scholarship Award</li>
        <li>BeArt Presets Academic Scholarship</li>
        <li>Education Future International Scholarship</li>
        <li>Global Citizen Scholarship</li>
        <li>Narotam Sekhsaria Scholarship</li>
        <li>International Peace Scholarship for Women</li>
      </ol>
      <p>There are different types of scholarships for international students in Canada like, merit-based scholarships, need-based scholarships, athletic scholarships, subject-specific scholarships, regional scholarships, government-funded scholarships, college or university-funded scholarships, and so on. The University of Toronto scholarships for Indian students, McGill University Scholarship, the University of British Columbia scholarships for international students, and The University of Alberta Scholarships are a few of the scholarships provided by certain institutions. Quebec Provincial Government Scholarship is an example of a scholarship that is provided to students in certain provinces.</p>`,
    },
    {
      heading: "Connect with us",
      content:
        "<p>with over 10 years of experience. We help students achieve their dreams of studying abroad, offering expert guidance and support every step of the way.</p>",
    },
  ],
};

countryDetails[2] = {
  name: "Study in UK",
  image: coursesGrid2,
  link: "/study-in-uk-agency",
  pageTitle: "Study in UK",
  pageDesc: `<h6>At a Glance</h6>
    <p>The United Kingdom, made up of England, Wales, Northern Ireland, and Scotland, is renowned for its world-class higher education. With a rich variety of programs in fields like Arts, Culture, Religion, Fashion, Engineering, Management, Hospitality and Tourism, Medicine, and Architecture, the UK attracts thousands of international students each year. Prestigious universities across the UK offer the ideal environment to advance your academic journey.</p>
    <p>Partner with Guidance Plus,  one of the leading UK education consultants, to secure your spot at one of these esteemed institutions and take the first step toward your future.</p>`,
  pageContent: [
    {
      heading: "The Upsides",
      content: `
      <ol>
        <li>The UK offers a wide range of courses tailored to career aspirations.</li>
        <li>It has over 100 years of education expertise.</li>
        <li>Free health insurance for students</li>
        <li>A two-year post-study work visa is available after completing a program.</li>
        <li>Courses often include internships and sandwich placements.</li>
        <li>UK degrees are internationally recognized.</li>
        <li>Most universities are government-owned, offering a high volume of options.</li>
        <li>International students can work 20 hours per week during term time and 40 hours during vacations.</li>
        <li>Highest living standards</li>
      </ol>`,
    },
    {
      heading: "Requirements",
      content: `<p>Generally, IELTS is required for studying in the UK, but there are alternative options available for demonstrating English proficiency:</p>
        <ol>
        <li>English Medium Education: Some universities accept a Medium of Instruction (MOI) certificate from English medium schools.</li>
        <li>12th Grade English Scores: Class 12th English scores can be considered as proof of proficiency.</li>
        <li>Online Interview: Certain universities may conduct interviews via platforms like Skype or Microsoft Teams.</li>
        <li>Other Exams: Some universities offer their own qualifying exams instead of IELTS, TOEFL, or PTE.</li>
        </ol>
        
        <h6>Documents Needed:</h6>
        
        <ol>
        <li>10th and 12th mark sheets</li>
        <li>Bachelor’s semester mark sheets, provisional certificate, and consolidated mark sheet</li>
        <li>Medium of Instruction (MOI) certificate, if required</li>
        <li>Passport, Letters of Recommendation, Resume, Statement of Purpose</li>
        <li>University offer letter, Letter of Experience (if applicable)</li>
        <li>Other academic transcripts</li>
        <li>Visa Requirements:</li>
        </ol>
        
        <p>For a Tier 4 UK student visa, English proficiency is required.</p>
        <ol>
        <li>Minimum band scores: Diploma (5.0), Bachelor’s (6.0), Master’s (6.0–6.5).</li>
        <li>If IELTS is waived, request confirmation from the university for visa eligibility.</li>
        </ol>`,
    },
    {
      heading: "Intakes",
      content: `<p>Intake Availability:<br/>
        Majority of programs are available in September and January.<br/>
        Minor intakes are offered in March, April, and May.</p>
        <h6>Admission Process:</h6>
        <p>Admission seekers should consult with our counselors for program details.<br/>
        After selecting the program and university, we will apply on behalf of the candidate to the respective university.</p>

        <h6>Offer Letter and Medical Test:</h6>
        <p>Once the university issues the offer letter, the aspirant must qualify for a medical test.</p>

        <h6>Financial Credibility:</h6>
        <p>After the medical test, the student must demonstrate financial credibility to obtain the CAS (Confirmation of Acceptance for Studies) letter.</p>

        <h6>Financial Requirements:</h6>
        <p><b>Outer London:</b> Tuition fees + living expenses of approximately £9,207 must be maintained in the student’s account for 28 days.</p>
        <p><b>Inner London:</b> Tuition fees + living expenses of approximately £12,006 must be kept in the student’s or their parent’s account for 28 days.</p>

        <h6>Visa and Travel:</h6>
        <p>After obtaining the visa, the student can fly to the UK to pursue their dream course.</p>



        <h6>Prominent scholarships</h6>
        <ol>
        <li>GREAT Scholarship: Supports international students to study in the UK.</li>
        <li>Chevening Scholarship: Funded by the UK government for outstanding students pursuing a master’s degree.</li>
        <li>Commonwealth Scholarships: For students from low and middle-income Commonwealth countries.</li>
        <li>Global Wales Scholarship: Funding for undergraduate and postgraduate programs in Wales.</li>
        <li>Fulbright Scholarship: Offers scholarships for Indian students to study in the UK.</li>
        <li>Sir Ratan Tata Scholarship: Financial assistance for Indian postgraduate students in social sciences and development studies.</li>
        </ol>`,
    },
  ],
};

countryDetails[3] = {
  name: "Study in France",
  image: coursesGrid3,
  link: "/study-in-france",
  pageTitle: "Study in France",
  pageDesc: `<h6>At a Glance</h6>
    <p>In terms of unique historical culture, geography and contributions to education, France remains one of the giants of Western European nations. With a specialization in educational excellence, the country welcomes non-French scholars from several continents. Students looking forward to studying engineering, arts and humanities, business, gastronomy as well as fashion, will have their needs well catered for in French institutions. The rich cultural life coupled with the desire for innovation and discovery typifies the frenzied academic environment. Furthermore, the safe and cosmopolitan nature of France serves as an asset in enrichening one both professionally and personally. In many ways, French culture captured us, but most of all the opportunity to study in France – we are glad to fulfill your wish and help you in each step of this process!</p>`,
  pageContent: [
    {
      heading: "The Upsides",
      content: `<ul class="education-list-style-one">
        <li>Affordable and Quality Education: High standards at competitive prices.</li>
        <li>Multicultural Society: A diverse and inclusive environment.</li>
        <li>English-Taught Courses: Many universities offer programs in English.</li>
        <li>Attractive Tuition Fees: Cost-effective options for international students.</li>
        <li>Research and Development Opportunities: Excellent facilities and support for innovation.</li>
        <li>Available Scholarships: Various funding options to assist students.</li>
        <li>Government Accommodation Assistance: Support for securing housing.</li>
        <li>Travel Opportunities: An appealing destination for exploring Europe.</li>
        </ul>

        <h6>Requirements</h6>
        <p><b>Language of Instruction:</b> Courses are offered in both French and English.</p>
        <p><b>French Language Proficiency:</b> No need for a French language test for international students.</p>

        <h6>Academic Qualifications:</h6>
        <p><b>Bachelor’s Degree:</b> Minimum of 50% in 12th grade required.</p>
        <p><b>Master’s Degree:</b> Minimum of 50% in Bachelor’s degree required.</p>

        <h6>Foundation and Diploma Programs:</h6>
        <p>Students with around 50% can qualify for these programs.</p>
        <p><b>Age Requirement:</b> Students must be at least 18 years old before joining a degree program.</p>

        <h6>Mandatory Documentation:</h6>
        <p>MOI (Medium of Instruction) certificate must be submitted for enrollment.</p>
        <p>IELTS and French language proficiency are non-mandatory.</p>
        <h6>Intakes</h6>
        <p>Major intakes are available in February and September</p>`,
    },
    {
      heading: "Prominent scholarships",
      content: `<ul class="education-list-style-one">
        <li>Scholarships for International Students in France</li>
        <li>Charpak Scholarship Program</li>
        <li>Eiffel Scholarship Program of Excellence</li>
        <li>Erasmus+ Mobility Scholarships for Foreign Students</li>
        <li>French+ Sciences Scholarships</li>
        <li>Make Our Planet Great Again (MOPGA) Scholarships</li>
        <li>Legrand Empowering Scholarship</li>
        <li>Amba Damia Scholarship</li>
        <li>Emile-Boutmy Scholarship</li>
        <li>Accommodation Assistance </li>
        </ul>`,
    },
    {
      heading: "CAF (Caisse des Allocations Familiales):",
      content: `<ul>
        <li>Financial support provided by the French government to help with housing-related costs.</li>
        <li>Available to international students renting a flat, furnished rental, studio, or flat share.</li>
        <li>Eligible students can receive approximately €180 to €200 each month to assist with accommodation expenses.</li>
        </ul>
        `,
    },
  ],
};

countryDetails[4] = {
  name: "Study in Germany",
  image: coursesGrid4,
  link: "/study-in-germany",
  pageTitle: "Study in Germany",
  pageDesc: `<h6>At a Glance</h6>
    <p>Due to excellent quality education and advanced research, Germany remains among the most popular countries for international students. Consisting of 16 federal states, Germany provides comprehensive studies in Engineering, Medicine, Natural Sciences, Business, Technology, Arts, Architecture and other fields. Several eminent universities and technical colleges create a perfect academic milieu for their students by generating new ideas on the background of past. With relatively low tuition and high practical employment opportunities, Germany grows students from every corner of the world making it a great country to study.</p>

    <h6>The Upsides</h6>

    <p><b>Better Universities:</b> Germany bears some of the most prestigious universities that are famous for their best academic records.</p>
    <p><b>Different Degree Courses:</b> Students have the options to select different degree programs in various study fields.</p>
    <p><b>Globally Accepted Study Programs:</b> Degrees from German universities are widely accepted and recognized across the world.</p>
    <p><b>Affordability:</b> Cost of living in Germany is fairly better than many other countries for students.</p>
    <p><b>Flexible Travel Options:</b> Applying for a German student visa also provides you with a Schengen visa which means that you can visit 27 other European countries as well using the same visa.</p>
    <p><b>Part-time work:</b> Students have to complete their studies as well then they are headed for a glimpse of valuable work experience and supplementary in-cash support.</p>
    <p><b>Higher employability rate:</b> Studies show that there is a high probability of you being employed as against graduates from other countries.</p>
    <p><b>Diverse Community:</b> Germany is a country of multi-cultural These factors makes it easier to foreign students to adapt and feel welcome.</p>
    <p><b>Free language training:</b> Students have access to free courses in German, which can facilitate the daily life and their experience.</p>`,
  pageContent: [
    {
      heading: "Requirements",
      content: `<p><b>Higher Education Entrance Qualification:</b>
        A high school diploma, school-leaving certificate, or university entrance exam result is typically required for international and undergraduate students.</p>
        <p><b>Program-Specific Requirements:</b>
        You may need to meet additional specific requirements based on your chosen program.</p>
        <p><b>English Language Proficiency:</b>
        Proof of English proficiency may be required, typically through TOEFL or IELTS scores.</p>
        <p><b>German Language Proficiency:</b>
        Proficiency in German is non-mandatory for studying at colleges or universities in Germany.</p>`,
    },
    {
      heading: "Course Qualifications",
      content: `<p><b>Bachelor’s Degree:</b> Minimum of 12/13 years of education with at least 50% marks in the 12th grade.</p>
        <p><b>IELTS Requirement:</b> Overall score of 6.0, with no individual score lower than 6.0.</p>
        <p><b>Master’s Degree:</b> Bachelor’s degree with 50% and above.</p>
        <p><b>IELTS Requirement:</b> Overall score of 5.5, with no individual score lower than 6.0.</p>

        <h6>Duration</h6>
        <p><b>Undergraduate Degrees:</b> Typically take a minimum of 3 years.</p>
        <p><b>Postgraduate Degrees:</b> Usually require 2 years.</p>`,
    },
    {
      heading: "Intakes",
      content: `<h6>Summer Intake:</h6>
        <p>Application Period: March/April</p>
        <p>Tuition Fees: Approximately 10,236 Euros for the first year.</p>

        <h6>Winter Intake:</h6>
        <p>Application Period: September/October</p>
        <p>Certain private universities may have additional intakes in February, May, and June.</p>

        <h6>Prominent scholarships</h6>
        <ul class="education-list-style-one">
        <li>DAAD Scholarship Programs</li>
        <li>Erasmus Scholarship Programs</li>
        <li>Heinrich Böll Foundation Scholarships</li>
        <li>DeutschlandStipendium National Scholarship Programme</li>
        <li>Konrad-Adenauer-Stiftung Scholarships</li>
        <li>Bayer Foundation Awards</li>
        <li>International Ambassador Scholarship</li>
        <li>International Student Financial Aid (ISFA)</li>
        </ul>`,
    },
  ],
};

countryDetails[5] = {
  name: "Study in Dubai",
  image: coursesGrid5,
  link: "/study-in-dubai",
  pageTitle: "Study in Dubai",
  pageDesc: `<h6>At a Glance</h6>
    <p>Dubai is part of the largest and most populous metropolitan area in the United Arab Emirates (UAE), situated on the Persian Gulf and backed by the Arabian desert.Known for its skyscapers, high standard of living and its reputable higher education institutions, Dubai makes for a popular study destination. It is a truly cosmopolitan city, attracting students from all around the world.Strong post-study work prospects, a booming economy, tax-free income, easy visa policies and a growing job market are additional advantages for international students who wish to study in Dubai.</p>`,
  pageContent: [
    {
      heading: "The Upsides",
      content: `<ul class="education-list-style-one">
        <li>Afforadable cost</li>
        <li>Low travel costs and time</li>
        <li>Multicultural Experience</li>
        <li>IELTS is not mandatory</li>
        <li>Age gap and year gap accepted</li>
        <li>Opportunity to learn in the branch institutions of top universities in UK, USA, Australia and so on at lower cost</li>
        <li>Opportunity to visit or study on the mother campuses in different nations.</li>
        </ul>`,
    },
    {
      heading: "Academic Qualifications:",
      content: `<ul class="education-list-style-one">
        <li>Bachelor’s Degree: Minimum of 50% in 12th grade required.</li>
        <li>Master’s Degree: Minimum of 50% in Bachelor’s degree required.</li>
        <li>PG Diploma: Minimum of 50% in Bachelor’s degree required.</li>
        <li>Students with less than 50% marks for qualifying exams can attend foundation courses and it leads to securing their dream institutions with the course of their choice.</li>
        </ul>`,
    },
    {
      heading: "Intakes",
      content: `<p>Major intakes are available in Januray and September</p>
        <p>Modul University has an intake in December or February.</p>
        <p>Canadian University, Murdoch University and University of Wollongong has an intake in May along with Intakes of January and September.</p>
        <ul class="education-list-style-one">
        <li>Prominent scholarships</li>
        <li>UG Merit Scholarship</li>
        <li>Khalifa University Scholarships</li>
        <li>UAE University Full Scholarship</li>
        <li>Graduate Merit Scholarships</li>
        <li>Partial Tution Scholarship for International Students</li>
        <li>Chancellor Fellowship for Graduate Students at UAE University</li>
        </ul>`,
    },
  ],
};

// countryDetails[1] = {
//     name: "UK",
//     image: coursesGrid2,
//     link: "/study-in-uk-agency",
//     pageTitle: "UK",
//     pageDesc: "",
//     pageContent: [
//       {
//         heading: "",
//         content: "",
//       },
//     ],
//   };
export const COUNTRY_DETAILS = countryDetails;
